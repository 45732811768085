import {
  AWSDataSourceSettings,
  AWSDataSourceType,
  RedshiftConfig,
  AWSDataSourceJsonData,
  CreateDataSourceParams,
} from 'types/types';
import { createDataSource, buildARN, serviceToDSMap, shouldSetAssumeRole } from './datasource';

export interface RedshiftJsonData extends AWSDataSourceJsonData {
  assumeRoleArn?: string;
  clusterIdentifier?: string;
  database?: string;
  dbUser?: string;
}

export type RedshiftDataSourceSettings = AWSDataSourceSettings<RedshiftJsonData>;

export interface CreateRedshiftDataSourceParams extends CreateDataSourceParams {
  clusterIdentifier: string;
  config?: RedshiftConfig;
}

export function createRedshiftDataSource(
  params: CreateRedshiftDataSourceParams,
  installedDSNames: Set<string>,
  workspaceAccountId: string
) {
  let dsSettings: RedshiftDataSourceSettings = {
    jsonData: {
      authType: 'ec2_iam_role',
      defaultRegion: params.region,
      ...params.config,
    },
  };

  console.log(params.config);

  let dsName = `${serviceToDSMap.redshift.name} ${params.region} ${params.clusterIdentifier}`;

  if (params.account) {
    dsName = `${dsName} ${params.account.id}`;
  }

  if (params.account && params.roleName && dsSettings.jsonData && shouldSetAssumeRole(params, workspaceAccountId)) {
    dsSettings.jsonData.assumeRoleArn = buildARN(params.account.id, params.roleName);
  }

  if (dsSettings.jsonData) {
    dsSettings.jsonData.clusterIdentifier = params.clusterIdentifier;
  }

  return createDataSource(AWSDataSourceType.Redshift, dsName, dsSettings, installedDSNames);
}
