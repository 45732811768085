import { SelectableValue } from '@grafana/data';
import { maxSatisfying } from 'semver';
import { AWSDataSourceJsonData, AWSDataSourceSettings, AWSDataSourceType, CreateDataSourceParams } from 'types/types';
import { buildARN, createDataSource, serviceToDSMap, shouldSetAssumeRole } from './datasource';

export enum ESFlavor {
  Elasticsearch = 'elasticsearch',
  OpenSearch = 'opensearch',
}

export interface ESVersion {
  flavor: ESFlavor;
  version: string;
}

export interface ElasticsearchJsonData extends AWSDataSourceJsonData {
  database: string;
  timeField?: string;
  version?: string;
  flavor?: ESFlavor;
  esVersion?: ESVersion['version'];
  interval?: string;
  timeInterval?: string;
  maxConcurrentShardRequests?: number;
  logMessageField?: string;
  logLevelField?: string;
  dataLinks?: DataLinkConfig[];
  sigV4Auth: boolean;
  sigV4AuthType: string;
  sigV4AssumeRoleArn?: string;
  sigV4Region: string;
  pplEnabled: boolean;
}

export type DataLinkConfig = {
  field?: string;
  url?: string;
  datasourceUid?: string;
};

export type OpenSearchDataSourceSettings = AWSDataSourceSettings<ElasticsearchJsonData>;

export interface CreateOpenSearchDataSourceParams extends CreateDataSourceParams {
  id: string;
  endpoint: string;
  database?: string;
  version?: ESVersion;
}

export function createOpenSearchDataSource(
  params: CreateOpenSearchDataSourceParams,
  installedDSNames: Set<string>,
  workspaceAccountId: string
) {
  let dsSettings: OpenSearchDataSourceSettings = {
    url: params.endpoint,
    jsonData: {
      database: params.database || '',
      timeField: '@timestamp',
      sigV4Auth: true,
      sigV4AuthType: 'ec2_iam_role',
      sigV4Region: params.region,
      pplEnabled: true,
      ...(params.version || { flavor: ESFlavor.OpenSearch, version: '1.0.0' }),
    },
  };

  if (params.account && params.roleName && dsSettings.jsonData && shouldSetAssumeRole(params, workspaceAccountId)) {
    dsSettings.jsonData.sigV4AssumeRoleArn = buildARN(params.account.id, params.roleName);
  }

  const dsName = `${serviceToDSMap.opensearch.name} ${params.id}`;
  return createDataSource(AWSDataSourceType.OpenSearch, dsName, dsSettings, installedDSNames);
}

/**
 * A list of versions available in the OpenSearch plugin.
 * Ideally should be kept in sync with `AVAILABLE_VERSIONS` in https://github.com/grafana/opensearch-datasource/blob/main/src/configuration/utils.ts
 */
export const AVAILABLE_ES_VERSIONS: Array<SelectableValue<ESVersion>> = [
  {
    label: 'OpenSearch 1.0.x',
    value: {
      flavor: ESFlavor.OpenSearch,
      version: '1.0.0',
    },
  },
  {
    label: 'OpenSearch 1.1.x',
    value: {
      flavor: ESFlavor.OpenSearch,
      version: '1.1.0',
    },
  },
  {
    label: 'OpenSearch 1.2.x',
    value: {
      flavor: ESFlavor.OpenSearch,
      version: '1.2.0',
    },
  },
  {
    label: 'OpenSearch 1.3.x',
    value: {
      flavor: ESFlavor.OpenSearch,
      version: '1.3.0',
    },
  },
  {
    label: 'Elasticsearch 7.0+',
    value: {
      flavor: ESFlavor.Elasticsearch,
      version: '7.0.0',
    },
  },
  {
    label: 'Elasticsearch 6.0+',
    value: {
      flavor: ESFlavor.Elasticsearch,
      version: '6.0.0',
    },
  },
  {
    label: 'Elasticsearch 5.6+',
    value: {
      flavor: ESFlavor.Elasticsearch,
      version: '5.6.0',
    },
  },
  {
    label: 'Elasticsearch 5.0+',
    value: {
      flavor: ESFlavor.Elasticsearch,
      version: '5.0.0',
    },
  },
  {
    label: 'Elasticsearch 2.0+',
    value: {
      flavor: ESFlavor.Elasticsearch,
      version: '2.0.0',
    },
  },
];

/**
 * @param rawVersion the raw `ElasticsearchVersion` string returned from https://docs.aws.amazon.com/cli/latest/reference/es/describe-elasticsearch-domains.html
 * can be a string in the form of `x.x` for Elasticsearch clusters or `OpenSearch_x.x` for OpenSearch.
 * @return a valid `ESVersion` object from `AVAILABLE_ES_VERSIONS` having the higher semver value for `version` if the input matches the described pattern and `AVAILABLE_VERSIONS`
 * contains a suitable option, `undefined` otherwise.
 * An option is considered suitable if its `version` is lower or equal to the `x.x` substring of `rawVersion`, and `flavor` is `elasticsearch` for
 * `rawVersion` matching the pattern `x.x` or `opensearch` for `rawVersion` matching `OpenSearch_x.x`.
 * @see AVAILABLE_ES_VERSIONS.
 */
export const extractESVersion = (rawVersion = ''): ESVersion | undefined => {
  if (/^\d+\.\d+/.test(rawVersion)) {
    return getBestMatchingVersion(ESFlavor.Elasticsearch, rawVersion);
  }

  const match = rawVersion.match(/^OpenSearch_(\d+\.\d+)/)?.[1];
  if (match) {
    return getBestMatchingVersion(ESFlavor.OpenSearch, match);
  }

  return;
};

const getBestMatchingVersion = (flavor: ESFlavor, version: string) => {
  const options = AVAILABLE_ES_VERSIONS.filter((v) => v.value?.flavor === flavor);
  const versions = options.map((v) => v.value?.version!);
  const bestMatchVersion = maxSatisfying(versions, `<=${version}`);
  return options.find((v) => v.value?.version === bestMatchVersion)?.value;
};
