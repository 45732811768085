import { Tab } from '../components/Navigation/Tabs';

export const getNavModel = (tabs: Tab[], path: string, activeTab: Tab, logoUrl: string) => {
  const children = tabs.map((t) => ({
    text: t.label,
    id: t.label,
    icon: t.icon,
    active: t.id === activeTab?.id,
    // Creating a relative URL so it works even if Grafana is installed under a custom base URL.
    url: `${path.replace(/^\//, '')}?tab=${t.id}`,
  }));

  const main = {
    text: 'AWS Data Sources',
    subTitle: 'Create data sources for all your AWS resources',
    url: path,
    img: logoUrl,
    children,
  };

  return {
    main,
    node: main,
  };
};

export const findActiveTab = (tabs: Tab[], selectedTabId: string) => {
  return tabs.find((t) => t.id === selectedTabId) || tabs[0];
};
