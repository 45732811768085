import React, { useState } from 'react';
import { css } from '@emotion/css';
import { AppPluginMeta, GrafanaTheme } from '@grafana/data';
import { Button, InlineFormLabel, Input, useStyles } from '@grafana/ui';
import { TabComponentProps } from '../Navigation/Tabs';
import { ProvisioningAppServiceConfig, ProvisioningAppSettings } from 'types/types';
import { updatePlugin } from 'api';

export interface Props extends TabComponentProps {
  meta: AppPluginMeta<ProvisioningAppSettings>;
}

const getDefaultSettings = (): ProvisioningAppServiceConfig => ({
  cloudwatch: {
    customMetricsNamespaces: '',
  },
  prometheus: {
    timeInterval: '',
    queryTimeout: '',
  },
});

export const Settings = ({ meta }: Props) => {
  const styles = useStyles(getStyles);

  const [servicesSettings, setServicesSettings] = useState<ProvisioningAppServiceConfig>(
    meta.jsonData?.serviceConfig || getDefaultSettings()
  );

  const saveSettings = async () => {
    updateAndReload({
      ...meta,
      jsonData: {
        ...meta.jsonData,
        serviceConfig: servicesSettings,
      },
    });
  };

  const resetSettings = async () => {
    updateAndReload({
      ...meta,
      jsonData: {
        ...meta.jsonData,
        serviceConfig: getDefaultSettings(),
      },
    });
  };

  const updateAndReload = async (data: Partial<AppPluginMeta<ProvisioningAppSettings>>) => {
    try {
      await updatePlugin(meta.id, data);

      // Reloading the page as the changes made here wouldn't be propagated to the actual plugin otherwise.
      // This is not ideal, however unfortunately currently there is no supported way for updating the plugin state.
      window.location.reload();
    } catch (e) {
      console.error('Error while updating the plugin', e);
    }
  };

  return (
    <>
      <h2>Default data source settings</h2>
      <div className={styles.serviceSection}>
        <h3>CloudWatch</h3>
        <div>
          <div className="gf-form-inline">
            <div className="gf-form">
              <InlineFormLabel className="width-12" tooltip="Namespaces of Custom Metrics.">
                Custom Metrics
              </InlineFormLabel>
              <Input
                className="width-30"
                placeholder="Namespace1,Namespace2"
                value={servicesSettings.cloudwatch?.customMetricsNamespaces}
                onChange={({ target }) =>
                  setServicesSettings({
                    ...servicesSettings,
                    cloudwatch: {
                      ...servicesSettings.cloudwatch,
                      customMetricsNamespaces: (target as HTMLInputElement).value,
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.serviceSection}>
        <h3>Prometheus</h3>
        <div className="gf-form-inline">
          <div className="gf-form">
            <InlineFormLabel
              className="width-12"
              tooltip="Set this to the typical scrape and evaluation interval configured in Prometheus. Defaults to 15s."
            >
              Scrape interval
            </InlineFormLabel>
            <Input
              className="width-6"
              value={servicesSettings.prometheus?.timeInterval}
              spellCheck={false}
              placeholder="15s"
              onChange={({ target }) =>
                setServicesSettings({
                  ...servicesSettings,
                  prometheus: {
                    ...servicesSettings.prometheus,
                    timeInterval: (target as HTMLInputElement).value,
                  },
                })
              }
            />
          </div>
        </div>
        <div className="gf-form-inline">
          <div className="gf-form">
            <InlineFormLabel className="width-12" tooltip="Set the Prometheus query timeout. Defaults to 60s.">
              Query timeout
            </InlineFormLabel>
            <Input
              className="width-6"
              value={servicesSettings.prometheus?.queryTimeout}
              spellCheck={false}
              placeholder="60s"
              onChange={({ target }) =>
                setServicesSettings({
                  ...servicesSettings,
                  prometheus: {
                    ...servicesSettings.prometheus,
                    queryTimeout: (target as HTMLInputElement).value,
                  },
                })
              }
            />
          </div>
        </div>
      </div>

      <div className="gf-form-button-row">
        <Button onClick={saveSettings}>Save</Button>
        <Button variant="destructive" onClick={resetSettings}>
          Reset
        </Button>
      </div>
    </>
  );
};

const getStyles = (theme: GrafanaTheme) => {
  return {
    serviceSection: css`
      margin-top: ${theme.spacing.xl};
      margin-bottom: ${theme.spacing.xl};
    `,
    migrationText: css`
      color: ${theme.colors.textWeak};
    `,
    innerLoader: css`
      margin-top: 100px;
      display: flex;
      justify-content: center;
    `,
  };
};
