import React, { useMemo } from 'react';
import { useTable, Column, useSortBy, CellProps } from 'react-table';
import { css } from '@emotion/css';
import { config } from '@grafana/runtime';
import { useStyles, Icon, LinkButton } from '@grafana/ui';
import { DataSourceSettings, GrafanaTheme } from '@grafana/data';
import { AWSDataSourceJsonData, AWSDataSourceSettings } from '../../types/types';

export interface Props {
  data: Array<AWSDataSourceSettings<AWSDataSourceJsonData>>;
  onChange?: (state: any) => void;
}

export const DataSourceTable = ({ data, onChange }: Props) => {
  const styles = useStyles(getStyles);

  const dataSourceTableColumns: Array<Column<DataSourceSettings>> = useMemo(
    () => [
      {
        id: 'dsTypeLogo',
        width: '40px',
        Cell: ({ row }: CellProps<any>) => {
          return (
            <figure className={styles.dataSourceLogo}>
              <img src={row.original.typeLogoUrl} />
            </figure>
          );
        },
      },
      {
        Header: 'Data source name',
        width: '100%',
        Cell: ({ row }: CellProps<any>) => {
          const ds = row.original;
          return <span>{ds.name}</span>;
        },
      },
      {
        id: 'dsSettingsButton',
        // width: '100%',
        Cell: ({ row }: CellProps<any>) => (
          <LinkButton variant="secondary" size="sm" href={`${config.appSubUrl}/datasources/edit/${row.original.id}`}>
            Go to settings
          </LinkButton>
        ),
      },
    ],
    [styles.dataSourceLogo]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: dataSourceTableColumns,
      data,
      useControlledState: (state) => {
        return useMemo(() => {
          if (data.length && onChange) {
            onChange(state);
          }
          return state;
        }, [state]);
      },
    },
    useSortBy
  );

  return (
    <table {...getTableProps()} className={styles.table}>
      <thead>
        {headerGroups.map((headerGroup) => {
          const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
          return (
            <tr key={key} {...headerGroupProps}>
              {headerGroup.headers.map((header, i) => {
                const { key, ...headerProps } = header.getHeaderProps(header.getSortByToggleProps());
                return (
                  <th
                    key={key}
                    {...headerProps}
                    //@ts-ignore
                    className={styles[header.className]}
                    style={{ width: header.width }}
                  >
                    {header.render('Header')}
                    {header.isSorted && <Icon name={header.isSortedDesc ? 'arrow-down' : 'arrow-up'} />}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          const { key, ...rowProps } = row.getRowProps();
          return (
            <tr key={key} {...rowProps}>
              {row.cells.map((cell) => {
                const { key, ...cellProps } = cell.getCellProps();
                return (
                  <td
                    key={key}
                    {...cellProps}
                    style={{ width: cell.column.width }}
                    //@ts-ignore
                    className={styles[cell.column.className]}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const getStyles = (theme: GrafanaTheme) => {
  return {
    container: css`
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;

      button {
        margin-top: -32px;
      }
    `,
    table: css`
      width: 100%;
      margin-top: ${theme.spacing.md};

      td {
        padding: 6px 8px;
        line-height: 30px;
        white-space: nowrap;
        a {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      th {
        padding: 6px 8px;
        text-align: left;
        line-height: 30px;
        white-space: nowrap;
      }

      tbody tr:nth-child(odd) {
        background: ${theme.colors.bg2};
      }
      tr {
        display: flex;
      }
    `,

    checkbox: css`
      display: flex;

      & > label {
        top: 6px;
      }
    `,
    nameColumn: css`
      width: 40%;
    `,
    dataSourceLogo: css`
      margin: 0px 4px;

      img {
        width: 24px;
      }
    `,
  };
};
