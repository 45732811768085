import React, { ReactElement, cloneElement } from 'react';
import { useAsync } from 'react-use';
import { LoadingPlaceholder } from '@grafana/ui';
import { API_ROOT } from '../../constants';
import { getBackendSrv } from '@grafana/runtime';
import { ServiceInfo } from '../../types/types';
import { PluginMeta } from '@grafana/data';

const backendSrv = getBackendSrv();

export interface Props {
  children: ReactElement;
}

const getPlugins = () => {
  return backendSrv.get(`api/plugins`);
};

const getServices = () => {
  return backendSrv.get(`${API_ROOT}/services`);
};

export const DataProvider = ({ children }: Props) => {
  const { value: plugins, loading: pluginsLoading } = useAsync<() => Promise<PluginMeta[]>>(getPlugins);
  const { value: loadedServices, loading: servicesLoading } = useAsync<() => Promise<ServiceInfo[]>>(getServices);

  const dataLoading = pluginsLoading || servicesLoading;

  const services = (loadedServices || []).map((service) => ({
    ...service,
    enabled: (plugins || []).some((plugin) => plugin.id === service.plugin),
  }));

  if (dataLoading) {
    return <LoadingPlaceholder text="loading" />;
  }
  return <div>{cloneElement(children, { services })}</div>;
};
