import {
  AWSDataSourceSettings,
  AWSDataSourceType,
  CloudWatchConfig,
  AWSDataSourceJsonData,
  CreateDataSourceParams,
} from 'types/types';
import { createDataSource, buildARN, serviceToDSMap, shouldSetAssumeRole } from './datasource';

export interface CloudWatchJsonData extends AWSDataSourceJsonData {
  timeField?: string;
  assumeRoleArn?: string;
  externalId?: string;
  customMetricsNamespaces?: string;
}

export type CloudWatchDataSourceSettings = AWSDataSourceSettings<CloudWatchJsonData>;

export interface CreateCloudWatchDataSourceParams extends CreateDataSourceParams {
  config?: CloudWatchConfig;
}

export function createCloudWatchDataSource(
  params: CreateCloudWatchDataSourceParams,
  installedDSNames: Set<string>,
  workspaceAccountId: string
) {
  let dsSettings: CloudWatchDataSourceSettings = {
    jsonData: {
      authType: 'ec2_iam_role',
      defaultRegion: params.region,
      ...params.config,
    },
  };

  let dsName = `${serviceToDSMap.cloudwatch.name} ${params.region}`;

  if (params.account) {
    dsName = `${dsName} ${params.account.id}`;
  }

  if (params.account && params.roleName && dsSettings.jsonData && shouldSetAssumeRole(params, workspaceAccountId)) {
    dsSettings.jsonData.assumeRoleArn = buildARN(params.account.id, params.roleName);
  }

  return createDataSource(AWSDataSourceType.CloudWatch, dsName, dsSettings, installedDSNames);
}
