import React from 'react';
import { css } from '@emotion/css';
import { GrafanaTheme } from '@grafana/data';
import { Alert, useStyles } from '@grafana/ui';

const maxElems = 3;

interface Props {
  warnings: string[];
}

export const WarningBox = ({ warnings }: Props) => {
  const styles = useStyles(getStyles);

  return (
    <div>
      <div className={styles.serviceSection}>
        <Alert title="" severity="warning">
          <h3>Warning</h3>
          <h5 className={styles.migrationText}>
            There were some errors while fetching your AWS information. Take a look carefully, please.
            <br />
            {warnings.slice(0, maxElems + 1).map((warn, i) => (
              <div key={i}>
                <strong>{warn}</strong>
              </div>
            ))}
            {warnings.length > maxElems && (
              <div>
                <i>And {warnings.length - maxElems} more...</i>
              </div>
            )}
          </h5>
        </Alert>
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme) => {
  return {
    serviceSection: css`
      margin-top: ${theme.spacing.xl};
      margin-bottom: ${theme.spacing.xl};
    `,
    migrationText: css`
      line-height: ${theme.typography.lineHeight.md};
      color: ${theme.colors.textWeak};
    `,
  };
};
