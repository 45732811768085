import { useAsync } from 'react-use';
import { getAllDataSources, getRegions } from 'api';
import { AWSDataSourceType, Region } from '../types/types';
import { DataSourceSettings } from '@grafana/data';

const getElasticDataSources = async () => {
  const response = await getRegions(AWSDataSourceType.Elasticsearch);

  const regionsRegexp = (response.regions ?? []).map((region: Region) => region.id).join('|');
  const elasticRegexp = `https:\/\/search-(.*?)-[a-zA-Z0-9]{26}.(${regionsRegexp}).es.amazonaws.com`;

  const awsElasticInstances = ({ type, url }: DataSourceSettings) =>
    (type === AWSDataSourceType.Elasticsearch || type === AWSDataSourceType.OpenDistro) &&
    new RegExp(elasticRegexp).test(url);

  return ((await getAllDataSources()) ?? []).filter(awsElasticInstances);
};

export const useElasticDataSources = () => {
  const { value: dataSources, loading } = useAsync(() => getElasticDataSources(), []);
  return { loading, dataSources };
};
