import {
  AWSDataSourceSettings,
  AWSDataSourceType,
  AthenaConfig,
  AWSDataSourceJsonData,
  CreateDataSourceParams,
} from 'types/types';
import { createDataSource, buildARN, serviceToDSMap, shouldSetAssumeRole } from './datasource';

export interface AthenaJsonData extends AWSDataSourceJsonData {
  assumeRoleArn?: string;
  catalog?: string;
  database?: string;
  workgroup?: string;
}

export type AthenaDataSourceSettings = AWSDataSourceSettings<AthenaJsonData>;

export interface CreateAthenaDataSourceParams extends CreateDataSourceParams {
  config?: AthenaConfig;
}

export function createAthenaDataSource(
  params: CreateAthenaDataSourceParams,
  installedDSNames: Set<string>,
  workspaceAccountId: string
) {
  let dsSettings: AthenaDataSourceSettings = {
    jsonData: {
      authType: 'ec2_iam_role',
      defaultRegion: params.region,
      ...params.config,
    },
  };

  let dsName = `${serviceToDSMap.athena.name} ${params.region} ${params.config?.catalog}`;

  if (params.account) {
    dsName = `${dsName} ${params.account.id}`;
  }

  if (params.account && params.roleName && dsSettings.jsonData && shouldSetAssumeRole(params, workspaceAccountId)) {
    dsSettings.jsonData.assumeRoleArn = buildARN(params.account.id, params.roleName);
  }

  return createDataSource(AWSDataSourceType.Athena, dsName, dsSettings, installedDSNames);
}
