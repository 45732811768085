import { AWSDataSourceJsonData, AWSDataSourceSettings, AWSDataSourceType } from '../types/types';
import { getBackendSrv } from '@grafana/runtime';
import { ESFlavor } from './opensearch';

export async function migrate<T extends AWSDataSourceJsonData>(dataSources: Array<AWSDataSourceSettings<T>>) {
  for (const dataSource of dataSources) {
    if (dataSource.type !== AWSDataSourceType.Elasticsearch && dataSource.type !== AWSDataSourceType.OpenDistro) {
      continue;
    }

    migrateToOpenSearch(dataSource);
  }

  return null;
}

const extractVersion = (versionNumber: number): string => {
  switch (versionNumber) {
    case 2:
      return '2.0.0';
    case 5:
      return '5.0.0';
    case 56:
      return '5.6.0';
    case 60:
      return '6.0.0';
    case 70:
    default:
      return '7.0.0';
  }
};

async function migrateToOpenSearch<T extends AWSDataSourceJsonData>(dataSource: AWSDataSourceSettings<T>) {
  let version = '1.0.0';
  let flavor = ESFlavor.OpenSearch;

  if (dataSource.jsonData?.hasOwnProperty('esVersion')) {
    let oldVersion: string | number = (dataSource.jsonData as any).esVersion;

    if (typeof oldVersion === 'number') {
      // User is migrating from an OpenDistro plugin or an old Elasticsearch plugin where esVersion was a number.
      // we coerce that version number to a string used by the OpenSearch plugin
      version = extractVersion((dataSource.jsonData as any).esVersion);
    } else if (typeof oldVersion === 'string') {
      // User is migrating from a new Elasticsearch plugin where esVersion is a semver string.
      version = oldVersion;
    }

    flavor = ESFlavor.Elasticsearch;
    delete (dataSource.jsonData as any).esVersion;
  }

  await getBackendSrv()
    .fetch({
      method: 'PUT',
      url: `/api/datasources/${dataSource.id}`,
      data: {
        ...dataSource,
        type: AWSDataSourceType.OpenSearch,
        jsonData: {
          ...dataSource.jsonData,
          pplEnabled: true,
          version,
          flavor,
        },
      },
      showSuccessAlert: true,
      showErrorAlert: false,
    })
    .toPromise();
}
