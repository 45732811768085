import React from 'react';
import { Select } from '@grafana/ui';
import { CellProps } from 'react-table';
import { AVAILABLE_ES_VERSIONS } from 'provisioning/opensearch';

export const ESVersionCell = ({ row, setCellState }: CellProps<any>) => (
  <Select
    width={20}
    options={AVAILABLE_ES_VERSIONS}
    disabled={!!row.original.esVersion}
    placeholder="Select Version"
    onChange={(value) => setCellState([row.id], 'esVersion', value.value)}
    value={AVAILABLE_ES_VERSIONS.find(
      (v) => v.value?.version === row.original.esVersion?.version && v.value?.flavor === row.original.esVersion?.flavor
    )}
  />
);
