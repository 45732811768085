import { AWSDataSourceSettings, AWSDataSourceType, AWSDataSourceJsonData, CreateDataSourceParams } from 'types/types';
import { createDataSource, buildARN, serviceToDSMap, shouldSetAssumeRole } from './datasource';

export interface SiteWiseJsonData extends AWSDataSourceJsonData {
  assumeRoleArn?: string;
  externalId?: string;
  endpoint?: string;
}

export type SiteWiseDataSourceSettings = AWSDataSourceSettings<SiteWiseJsonData>;

export function createSiteWiseDataSource(
  params: CreateDataSourceParams,
  installedDSNames: Set<string>,
  workspaceAccountId: string
) {
  let dsSettings: SiteWiseDataSourceSettings = {
    jsonData: {
      authType: 'ec2_iam_role',
      defaultRegion: params.region,
    },
  };

  let dsName = `${serviceToDSMap.sitewise.name} ${params.region}`;

  if (params.account) {
    dsName = `${dsName} ${params.account.id}`;
  }

  if (params.account && params.roleName && dsSettings.jsonData && shouldSetAssumeRole(params, workspaceAccountId)) {
    dsSettings.jsonData.assumeRoleArn = buildARN(params.account.id, params.roleName);
  }

  return createDataSource(AWSDataSourceType.SiteWise, dsName, dsSettings, installedDSNames);
}
