import { PluginMeta } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import { AWSDataSourceSettings } from 'types/types';
import { API_ROOT } from '../constants';

export const updatePlugin = async (pluginId: string, data: Partial<PluginMeta>) => {
  const response = await getBackendSrv().datasourceRequest({
    url: `/api/plugins/${pluginId}/settings`,
    method: 'POST',
    data,
  });

  return response?.data;
};

export const postDataSource = async (data: AWSDataSourceSettings<any>) => {
  return await getBackendSrv().post(`/api/datasources`, data);
};

export const getAllDataSources = async () => {
  return await getBackendSrv().get(`/api/datasources`);
};

export const getRegions = async (service: string) => {
  return await getBackendSrv().get(`${API_ROOT}/regions?service=${service}`);
};

export const getAccounts = async () => {
  return await getBackendSrv().get(`${API_ROOT}/accounts`);
};

export const getConfig = async () => {
  return await getBackendSrv().get(`${API_ROOT}/config`);
};
