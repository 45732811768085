import {
  AWSDataSourceJsonData,
  AWSDataSourceSettings,
  AWSDataSourceType,
  CreateDataSourceParams,
  PrometheusConfig,
} from 'types/types';
import { buildARN, createDataSource, serviceToDSMap, shouldSetAssumeRole } from './datasource';

export interface PrometheusJsonData extends AWSDataSourceJsonData {
  timeInterval?: string;
  queryTimeout?: string;
  httpMethod?: string;
  directUrl?: string;
  customQueryParameters?: string;
  disableMetricsLookup?: boolean;
  sigV4Auth: boolean;
  sigV4AuthType: string;
  sigV4AssumeRoleArn?: string;
  sigV4Region: string;
}

export type PrometheusDataSourceSettings = AWSDataSourceSettings<PrometheusJsonData>;

export interface CreatePrometheusDataSourceParams extends CreateDataSourceParams {
  id: string;
  endpoint: string;
  config?: PrometheusConfig;
}

export function createPrometheusDataSource(
  params: CreatePrometheusDataSourceParams,
  installedDSNames: Set<string>,
  workspaceAccountId: string
) {
  const dsSettings: PrometheusDataSourceSettings = {
    url: params.endpoint,
    jsonData: {
      ...params.config,
      httpMethod: 'GET',
      sigV4Auth: true,
      sigV4AuthType: 'ec2_iam_role',
      sigV4Region: params.region,
    },
  };

  if (params.account && params.roleName && dsSettings.jsonData && shouldSetAssumeRole(params, workspaceAccountId)) {
    dsSettings.jsonData.sigV4AssumeRoleArn = buildARN(params.account.id, params.roleName);
  }

  const dsName = `${serviceToDSMap.prometheus.name} ${params.id}`;
  return createDataSource(AWSDataSourceType.Prometheus, dsName, dsSettings, installedDSNames);
}
