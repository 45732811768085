import React, { useState, useEffect } from 'react';
import { AppRootProps } from '@grafana/data';
import { Services } from '../Services';
import { DataSources } from '../DataSources';
import { Settings } from '../Settings';
import { findActiveTab, getNavModel } from '../../utils';
import { DataProvider } from '../App/DataProvider';
import { ServiceInfo } from '../../types/types';

export type Tab = {
  id: string;
  label: string;
  icon: string;
  enabledByFeatureFlag?: string;
  component: React.JSXElementConstructor<any>;
};

export interface TabComponentProps {
  services: ServiceInfo[];
}

export const tabs: Tab[] = [
  {
    id: 'services',
    label: 'AWS services',
    icon: 'cube',
    component: Services,
  },
  {
    id: 'datasources',
    label: 'Data sources',
    icon: 'database',
    component: DataSources,
  },
  {
    id: 'settings',
    label: 'Settings',
    icon: 'sliders-v-alt',
    component: Settings,
  },
];

export const Tabs = ({ query, onNavChanged, path, meta }: AppRootProps) => {
  const selectedTabId = query.tab;
  const [activeTab, setActiveTab] = useState(findActiveTab(tabs, selectedTabId));
  const logoUrl = meta.info.logos.large;

  useEffect(() => {
    const activeTab = findActiveTab(tabs, selectedTabId);

    setActiveTab(activeTab);
    onNavChanged(getNavModel(tabs, path, activeTab, logoUrl));
  }, [selectedTabId, path, onNavChanged, logoUrl]);

  const Component = activeTab?.component;
  return (
    <DataProvider>
      <Component query={query} meta={meta} />
    </DataProvider>
  );
};
